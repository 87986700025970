<template>
  <VueContentHolder id="divChangePasswordWrapper">
    <VueForm ref="form" :bodyPadding="['0', '20']">
      <VueSpacer level="20" />
      <VueInput
        :type="passwordType"
        name="currentPassword"
        id="currentPassword"
        ref="currentPassword"
        validatorName="Şifre"
        placeholder="Mevcut Şifre"
        v-model="formModel.currentPassword"
        :messageOnTop="true"
        validationRules="required|max:20|min:1|is_not:newPassword"
      />
      <VueSpacer level="10" />
      <VueInput
        :type="passwordType"
        name="newPassword"
        id="newPassword"
        ref="newPassword"
        validatorName="Şifre"
        placeholder="Yeni Şifre"
        v-model="formModel.newPassword"
        :messageOnTop="true"
        validationRules="required|max:20|min:8|atLeastTwoChar"
        :validState="true"
      >
        <VueButton
          :contentAlignment="constants.flexAlignment.center"
          :ghost="true"
          as="a"
          :iconName="getIcon.name"
          :iconSize="{ width: getIcon.width, height: getIcon.height }"
          @click="togglePassword"
        ></VueButton
      ></VueInput>
      <VueSpacer level="10" />
      <VueInput
        :type="passwordType"
        name="newPasswordRepeat"
        id="newPasswordRepeat"
        validatorName="Şifre"
        placeholder="Yeni Şifre Tekrar"
        v-model="formModel.newPasswordRepeat"
        :messageOnTop="true"
        validationRules="required|max:20|min:8|atLeastTwoChar|confirmed:newPassword"
        :validState="true"
      >
        <VueButton
          :contentAlignment="constants.flexAlignment.center"
          :ghost="true"
          as="a"
          :iconName="getIcon.name"
          :iconSize="{ width: getIcon.width, height: getIcon.height }"
          @click="togglePassword"
        ></VueButton>
      </VueInput>
    </VueForm>
    <VueSpacer level="40" />
    <div>
      <BrandButton
        :size="sizes.xxLarge"
        :contentAlignment="constants.flexAlignment.center"
        type="submit"
        :padding="{ vertical: 15, horizontal: 0 }"
        @click="handleSubmit"
        >GÜNCELLE</BrandButton
      >
    </div>
  </VueContentHolder>
</template>
<script>
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import { mapGetters } from 'vuex';
import { Settings } from '@/services/Api/index';

import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';

export default {
  name: 'ChangePassword',
  components: {
    VueInput,
    VueButton,
    VueSpacer,
    VueForm,
    BrandButton,
    VueContentHolder,
  },
  data: () => {
    return {
      formModel: {
        id: '',
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
      },
      submitted: false,
      passwordType: 'password',
    };
  },
  created() {
    this.setBottomBarStatus(false);
  },
  destroyed() {
    this.setBottomBarStatus(true);
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    getIcon() {
      return ICON_VARIABLES.eye;
    },
    routes() {
      return {
        auth: RoutesAthentication,
      };
    },
    ...mapGetters('auth', ['getUserModel']),
  },
  methods: {
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    async handleSubmit(e) {
      e.preventDefault();

      if (this.getUserModel.id) {
        this.formModel.id = this.getUserModel.id;
      } else {
        this.formModel.id = '';
      }

      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        this.submitted = true;
        Settings.changePassword(this.formModel).then(res => {
          if (res && res.data.Data) {
            this.$router.push('/auth/login/select');
            this.setBottomBarStatus(true);
          }
        });
      }
    },
    togglePassword() {
      this.passwordType === 'password'
        ? (this.passwordType = 'text')
        : (this.passwordType = 'password');
    },
  },
};
</script>
<style scoped lang="scss"></style>
